import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="December 2022"
      subnav="release-notes">
      <div id="December2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Check back throughout the month for more updates!
        </Paragraph>
        <div className={styles.releaseInfo}>
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Added>
            </div>
          </div> */}
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.27.0"
              versionUrl="https://github.com/hudl/uniform.hudl.com"
            />
            <div className={styles.changesList}>
              <Added>
                The{' '}
                <Link href="/components/modules/toggle/code?web#Toggle%20List">
                  Toggle List
                </Link>{' '}
                component to show multiple related Toggle visualizations.
              </Added>
              <Fixed>
                Missing <code>aria-disabled</code> accessibility attribute on{' '}
                <Link href="/components/modules/toggle">Toggle</Link> items.
              </Fixed>
              <Fixed>
                Typescript errors when using prop interfaces extended by Uniform
                components by changing <code>@types/*</code> packages to regular
                dependencies.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.4.0"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Added>
                The{' '}
                <Link href="/components/modules/toggle/code?reactnative#Toggle%20List">
                  Toggle List
                </Link>{' '}
                component to show multiple related Toggle visualizations.
              </Added>
            </div>
          </div>
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Fixed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Storybook" />
            <div className={styles.changesList}>
              <Removed>
                Sample change for{' '}
                <Link href="/components/modules/code">sample link</Link>.
              </Removed>
            </div>
          </div> */}
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
